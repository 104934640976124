































import { Component, Vue } from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';

@Component({
  components: {
    Loading
  }
})
export default class ModalWarning extends Vue {
  clickedCancel() {
    this.$emit('clickedCancel');
  }

  goToClass() {
    this.$emit('goToClass');
  }

  get isLoading() {
    return this.$store.getters.isLoadingChangedClass;
  }
}
