






























































import {
  Component, Vue, Watch, Prop 
} from 'vue-property-decorator';

import ContainerSidebar from '@/components/ContainerSidebar/ContainerSidebar.vue';
import Book from '@/components/Book/index.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import PlanUpdateSidebar from '@/components/PlanUpdateSidebar/index.vue';
import BookSidebarLoading from '@/components/BookSidebarLoading/index.vue';

import CoursesService from '@/services/Courses/CoursesService';
import BookService from '@/services/Book/BookService';

import { setNumber, formatDate } from '@/share/Util/UsefulFunctions/UserfulFunctions';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';
import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

import router from '@/router';
import store from '@/store';

const STATUS_LOADING_TRUE = true;
const STATUS_LOADING_FALSE = false;

const SUCCESSFUL_CHANGED_CLASS = true;
const ERRO_CHANGED_CLASS = false;

@Component({
  components: {
    ContainerSidebar,
    Book,
    ContainerFluid,
    PlanUpdateSidebar,
    BookSidebarLoading
  },
  filters: {
    setNumber,
    formatDate
  }
})
export default class CourseSidebar extends Vue {
  @Prop() cover!: string;
  @Prop() colorBook!: string;
  @Prop() title!: string;
  @Prop() dateInitial!: string;
  @Prop() dateFinished!: string;
  @Prop() quantityWeek!: string;
  @Prop() idClass!: string;
  @Prop({ default: 0 }) keyChangedClass!: number;
  @Prop() idClassSelected!: number;
  @Prop() timeTableCourse!: Record<string, any>;

  private dataTimeTableCourse: Record<string, any> = {};
  private keyComponent = 0;
  private coursesService = new CoursesService();
  private BookService = new BookService();

  created() {
    this.checkKeyAndCallClassChangeFunction();
  }

  mounted() {
    this.verifyTopicInTimetableCourse();
  }

  get isPlanFree() {
    return store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get showPeriod() {
    return this.dateInitial && this.dateFinished && this.quantityWeek;
  }

  get listClassCourse() {
    return store.getters.listClassCourseSelect;
  }

  get idTimeTableCourse() {
    return this.timeTableCourse.topics[0].archives[0].id;
  }

  get showLoading() {
    return this.colorBook || this.cover || this.title;
  }

  @Watch('listClassCourse', { deep: true })
  updateComponent() {
    if (this.keyChangedClass) this.keyComponent += 1;
  }

  @Watch('idClass')
  setTypeClass() {
    const TypesClass: Record<string, string> = {
      Janeiro: 'Extensiva',
      Fevereiro: 'Extensiva',
      Março: 'Extensiva',
      Abril: 'Semi-intensiva',
      Maio: 'Semi-intensiva',
      Junho: 'Intensiva',
      Julho: 'Intensiva',
      Agosto: 'Semi-intensiva',
      Setembro: 'Semi-intensiva'
    };

    const currentClass = this.listClassCourse.filter(
      (itemClass: Record<string, any>) => String(itemClass.id) === this.idClass
    );
    const classTitle: string = currentClass[0]?.title;

    return TypesClass[classTitle] || 'Extensiva';
  }

  verifyTopicInTimetableCourse() {
    if (Object.keys(this.timeTableCourse).length && this.timeTableCourse.topics.length) this.getDataMaterialTimeTable();
  }

  checkKeyAndCallClassChangeFunction() {
    if (this.keyChangedClass) {
      this.changeClass(this.idClassSelected);
      this.keyComponent += 1;
      this.keyChangedClass = 0;
    }
  }

  emitClassSelected(e: any) {
    this.setTrackAmplitude('Turma');

    this.$emit('classSelected', Number(e.target.value));
  }

  async changeClass(idClassSelected: number) {
    try {
      store.commit('setIsLoadingChangedClass', STATUS_LOADING_TRUE);
      const { slugCourse } = router.currentRoute.params;
      const { idCourses } = router.currentRoute.params;
      const course = this.getCourse(Number(idCourses));

      const response = await this.coursesService.saveCourseClassPreference(
        Number(idCourses),
        idClassSelected,
        course?.languageId || null
      );

      if (response.status === STATUS_RESPONSE_API.SUCCESS_CREATED) {
        if (slugCourse && idCourses && idClassSelected) {
          router.push({
            name: 'Courses',
            params: {
              slugCourse,
              idCourses,
              idClass: `${idClassSelected}`
            }
          });
        }
      }
      this.setDataModalChagendClass(SUCCESSFUL_CHANGED_CLASS);
    } catch (error) {
      this.setDataModalChagendClass(ERRO_CHANGED_CLASS);
      console.error({ error });
    } finally {
      store.commit('setIsLoadingChangedClass', STATUS_LOADING_FALSE);
    }
  }

  async getDataMaterialTimeTable() {
    try {
      const response: Array<Record<string, any>> = await this.BookService.getArchives([
        this.idTimeTableCourse
      ]);
      if (response) this.dataTimeTableCourse = response;
    } catch (error) {
      store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o cronograma do curso',
        status: 'error'
      });
    }
  }

  setDataModalChagendClass(status: boolean) {
    store.commit('setDataModalChagendClass', {
      isSuccessfulChangedClass: status,
      componentNameModal: 'ModalSuccessError'
    });
  }

  getCourse(idCourse: number) {
    const { courses } = store.getters.profile?.preferences;

    if (courses && courses.length) {
      const foundCourse = courses.find(
        (course: Record<string, any>) => course?.courseId === idCourse
      );

      if (foundCourse) return foundCourse;
    }

    return {};
  }

  openMaterialsInNewWindow() {
    this.setTrackAmplitude('Baixar cronograma');

    const a = document.createElement('a') as HTMLAnchorElement;

    a.href = `https://explicae.com.br/app/${this.dataTimeTableCourse[0].url}`;

    a.download = this.dataTimeTableCourse[0].title;

    a.target = '_blank';

    (document.body || document.documentElement).appendChild(a);
    a.click();
    // @ts-ignore
    a.parentNode.removeChild(a);
  }

  setTrackAmplitude(nameLocal: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'SidebarCourse',
          local: nameLocal
        }
      }
    });
  }
}
