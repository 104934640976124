





























































// Dependencies
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { orderBy } from 'lodash';

// Components
import Tab from '@/components/Subject/Tab/index.vue';
import MaterialsTab from '@/components/TabsAccordion/MaterialTab/index.vue';
import VideosTab from '@/components/TabsAccordion/VideosTab/index.vue';
import ExerciseTab from '@/components/TabsAccordion/ExerciseTab/index.vue';

// Services
import HistoricService from '@/services/Historic/historicService';
import BookService from '@/services/Book/BookService';
import ExerciseService from '@/services/Exercise/ExerciseService';
import VideoService from '@/services/Video/VideoService';

import copyObject from '@/share/Util/copyObject';

// Mixins
import accordionTab from '@/mixins/accordionTab';
import generalBooks from '@/mixins/generalBooks';

import { DataCourseSubjectList } from '@/globalInterfaces/Course';
import { TypeMaterial } from '@/services/Historic/historicInterface';

@Component({
  mixins: [accordionTab],
  components: {
    Tab,
    MaterialsTab,
    VideosTab,
    ExerciseTab
  }
})
export default class TabAccordion extends Mixins(generalBooks) {
  @Prop() lesson!: Record<string, any>;
  @Prop({ default: '0rem 1rem' }) padding!: string;
  @Prop() weekId!: number;
  @Prop({ default: '' }) titleWeek!: string;

  private orderBy = orderBy;

  private topic: Record<string, any> = {};
  private allQuestions: Array<Record<string, any>> = [];
  private allChecked: Record<string, any> = {
    videos: [],
    materials: [],
    exercices: []
  };
  private ids: Record<string, any> = {
    videos: [],
    archives: [],
    exercices: []
  };
  private files: Record<string, any> = {
    videos: [],
    archives: [],
    exercices: []
  };

  private historicService = new HistoricService();
  private BookService = new BookService();
  private ExerciseService = new ExerciseService();
  private VideoService = new VideoService();

  async mounted() {
    await this.loaderData();
  }

  get isPermission() {
    const { idCourses } = this.$route.params;
    return !this.isDisableCourse(Number(idCourses));
  }

  setHash() {
    return Math.floor(Math.random() * (1000 - 0)) + 0;
  }

  async loaderData() {
    this.getIds(this.lesson, 'videos');
    this.getIds(this.lesson, 'archives');
    this.getIds(this.lesson, 'exercices');

    await this.getAllQuestion(this.ids.exercices);

    await this.getFilesVideos(this.ids.videos, 'videos');
    await this.getFilesMaterials(this.ids.archives, 'archives');
    await this.getFilesExercices(this.ids.exercices, 'exercices');

    await this.getAllFilesChecked(this.ids.videos, 'video', 'videos');
    await this.getAllFilesChecked(this.ids.archives, 'materiais', 'materials');
    await this.getAllFilesChecked(this.ids.exercices, 'exercice', 'exercices');
    this.getTopic();
  }

  async getTopic() {
    this.topic = copyObject(this.lesson);
    this.topic.archives = this.files?.archives;
    this.topic.exercices = this.files?.exercices;
    this.topic.videos = this.files?.videos;
  }

  getIds(data: Record<string, any>, param: string) {
    if (!data?.topics || data?.topics.length <= 0) {
      if (data[param] && data[param].length) {
        const ids = data[param].map((item: Record<string, any>) => item?.id);
        this.ids[param] = [...this.ids[param], ...ids];
      }
    } else if (data?.topics && data?.topics.length) {
      data.topics.forEach((topic: Record<string, any>) => {
        this.getIds(topic, param);
      });
    }
  }

  async getAllFilesChecked(
    ids: Array<number>,
    historicParam: TypeMaterial,
    allChechedParam: string
  ) {
    if (ids && ids.length > 49) {
      const response = await this.getHistoric(historicParam, ids.slice(0, 100));

      this.allChecked[allChechedParam].push(...response);
      await this.getAllFilesChecked(ids.slice(100), historicParam, allChechedParam);
    } else if (ids && ids.length) {
      const response = await this.getHistoric(historicParam, ids);

      this.allChecked[allChechedParam].push(...response);
    }
  }

  async getAllQuestion(exercises: Array<number>) {
    if (exercises && exercises.length > 49) {
      const response = await this.ExerciseService.getQuestionsByExercise(exercises.slice(0, 100));
      this.allQuestions = [...this.allQuestions, ...response];
      await this.getAllQuestion(exercises.slice(100));
    } else if (exercises && exercises.length) {
      const response = await this.ExerciseService.getQuestionsByExercise(exercises);
      this.allQuestions = [...this.allQuestions, ...response];
    }
  }

  async getFilesVideos(ids: Array<number>, filesParam: string) {
    if (ids && ids.length > 49) {
      const response = await this.VideoService.getVideos(ids.slice(0, 100));
      const videosOrdered = this.getOrderByCourse(response);
      this.files[filesParam].push(...videosOrdered);
      await this.getFilesVideos(ids.slice(100), filesParam);
    } else if (ids && ids.length) {
      const response = await this.VideoService.getVideos(ids);
      const videosOrdered = this.getOrderByCourse(response);
      this.files[filesParam].push(...videosOrdered);
    }
  }

  async getHistoric(type: TypeMaterial, listIds: Array<number>) {
    try {
      const response = await this.historicService.getHistoric({ type, listIds });

      return response || [];
    } catch (error) {
      return [];
    }
  }

  getOrderByCourse(response: Array<any>) {
    let responseVideos = response;
    if (responseVideos.length) {
      responseVideos = responseVideos.map((video: any) => {
        const lessonVideo = this.lesson?.videos?.length
          && this.lesson.videos.find((videoLocal: any) => videoLocal.id === video.idVideo);
        if (lessonVideo && lessonVideo.order) return { ...video, order: lessonVideo.order };
        return video;
      });
    }
    return responseVideos;
  }

  async getFilesMaterials(ids: Array<number>, filesParam: string) {
    if (ids && ids.length > 49) {
      const response = await this.BookService.getArchives(ids.slice(0, 100));
      this.files[filesParam].push(...response);
      await this.getFilesMaterials(ids.slice(100), filesParam);
    } else if (ids && ids.length) {
      const response = await this.BookService.getArchives(ids);
      this.files[filesParam].push(...response);
    }
  }

  async getFilesExercices(ids: Array<number>, filesParam: string) {
    if (ids && ids.length > 49) {
      const response = await this.ExerciseService.getExercises(ids.slice(0, 100));
      this.files[filesParam].push(...response);
      await this.getFilesExercices(ids.slice(100), filesParam);
    } else if (ids && ids.length) {
      const response = await this.ExerciseService.getExercises(ids);
      this.files[filesParam].push(...response);
    }
  }

  async saveLastAccessedContent(data: DataCourseSubjectList) {
    try {
      const { titleWeek, weekId } = data;
      const { idCourses, idClass } = this.$route.params;

      const dataLastAccessedContent = {
        idClasses: Number(idClass),
        idWeek: weekId,
        idCourses: Number(idCourses),
        nameWeek: titleWeek
      };

      await this.historicService.saveCourseAccessWeek(dataLastAccessedContent);
    } catch (error) {
      console.error(error);
    }
  }

  getFilesType(discipline: Record<string, any>, type: string) {
    let listFileType: Array<Record<string, any>>;

    if (type === 'videos' && discipline?.assets) {
      listFileType = discipline.assets
        .filter((videos: Record<string, any>) => videos.type === type)
        .map((video: Record<string, any>) => video.id);
      return this.files.videos.filter((videoItem: Record<string, any>) => listFileType.includes(videoItem.idVideo));
    }

    if (type === 'exercices' && discipline?.assets) {
      listFileType = discipline.assets
        .filter((exercises: Record<string, any>) => exercises.type === type)
        .map((exercise: Record<string, any>) => exercise.id);
      return this.files.exercices.filter((exerciseItem: Record<string, any>) => listFileType.includes(exerciseItem.idExercice));
    }

    if (type === 'archives' && discipline?.archives) {
      listFileType = discipline.archives.map((archive: Record<string, any>) => archive.id);
      return this.files.archives.filter((archiveItem: Record<string, any>) => listFileType.includes(archiveItem.idArchive));
    }

    return [];
  }

  setData(item: Record<string, any>, type: string) {
    const { slugCourse, idCourses, idClass } = this.$route.params;
    const path = `${slugCourse}&${idCourses}&${idClass}&${this.weekId}`;

    if (type === 'video') {
      return {
        to: '/videos',
        from: `cursos/${path}`,
        file: item,
        id: item.idVideo,
        weekId: this.weekId,
        titleWeek: this.titleWeek
      };
    }

    if (type === 'material') {
      return {
        to: '/materiais',
        from: `cursos/${path}`,
        file: item,
        id: item.idArchive,
        weekId: this.weekId,
        titleWeek: this.titleWeek
      };
    }

    if (type === 'exercise') {
      return {
        to: '/exercicios',
        from: `cursos/${path}`,
        file: item,
        id: item.idExercice,
        type: 'instructions',
        weekId: this.weekId,
        titleWeek: this.titleWeek
      };
    }

    return '';
  }

  updateAllChecked(event: number) {
    const newAllChecked: Record<string, any> = this.allChecked;
    newAllChecked.materials.push({
      idobject: event,
      checked: true
    });
    this.allChecked = newAllChecked;
  }
}
