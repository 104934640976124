








































import { Component, Prop, Vue } from 'vue-property-decorator';
import Accordion from '@/components/Accordion/index.vue';
import TabAccordion from '../TabAccordion/TabAccordion.vue';

@Component({
  name: 'CourseTree',
  components: {
    Accordion,
    TabAccordion
  }
})
export default class CourseAccordion extends Vue {
  @Prop() week!: Record<string, any>;
  @Prop() weekId!: number;
  @Prop({ default: 1 }) padding!: number;
  @Prop() mainIndex!: number;
  @Prop() titleWeek!: string;

  setHash() {
    return Math.floor(Math.random() * (1000 - 0)) + 0;
  }

  verifyLesson(lesson: Record<string, any>) {
    return (
      (lesson?.topics && lesson?.topics.length)
      || (lesson?.videos && lesson?.videos)
      || (lesson?.archives && lesson?.archives)
      || (lesson?.exercices && lesson?.exercices)
    );
  }
}
